<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      메시지/알림
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="keyword"
            placeholder="알림을 검색하세요."
            @keypress.enter="getAlarmDataSearch"
          />
        </div>
        <router-link to="" @click.native="getAlarmDataSearch"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_tab_1">
      <div class="on">
        <router-link to="" @click.native="getAlarmData(true, true)"
          >알림</router-link
        >
      </div>
      <div><router-link to="" @click.native="msgInfo">메시지</router-link></div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_30">
      <div class="sect_list">
        <div class="box" v-for="list in listArray" :key="list.seq">
          <div class="left">
            <img
              src="@/assets/icons/icon-512x512v4.png"
              alt=""
              v-if="list.send_member_type === 'system'"
            />
            <img
              :src="list.reviewer_img"
              @error="replaceImg"
              alt=""
              v-if="list.send_member_type === 'reviewer'"
            />
            <img
              :src="list.seller_img"
              @error="replaceImg"
              alt=""
              v-if="list.send_member_type === 'seller'"
            />
          </div>
          <div class="right">
            <div class="r_top">
              <h4>
                {{
                  parseInt(list.send_member_seq) === 0
                    ? "램"
                    : list.send_member_nickname
                }}
              </h4>
              <div class="new_alarm" v-if="list.click_yn === 'N'">N</div>
              <p>{{ day(list.reg_dt) }}</p>
            </div>
            <div
              class="r_bottom"
              @click="moveLink(list.seq, list.target_link)"
              v-if="list.target_link !== null"
            >
              {{ list.content
              }}<router-link to=""
                ><img src="@/assets/images/sub/my_right_ico_1.png" alt=""
              /></router-link>
            </div>
            <div class="r_bottom" v-else>
              {{ list.content }}
            </div>
          </div>
        </div>
        <div
          class="box"
          v-if="listArray.length <= 0"
          style="text-align: center"
        >
          알림/메세지 가 없습니다.
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_more" v-if="parseInt(totalPage) > parseInt(page)">
      <router-link to="" @click.native="getAlarmData()"
        >{{ parseInt(limit) }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      keyword: "",
      page: 0,
    };
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      alarm: true,
    });
    this.getAlarmData(true);
  },
  computed: {
    ...mapState("mypage", ["result", "msg", "listArray", "totalPage", "limit"]),
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    getAlarmDataSearch() {
      // if (this.keyword.replace(/\s/g, "").length <= 0) {
      //   this.$toast.default("검색어를 입력해 주세요.");
      //   return false;
      // }
      this.getAlarmData(true);
    },
    async moveLink(seq, link) {
      await this.$store.dispatch("mypage/setMoveLink", {
        seq,
      });
      if (this.result) {
        this.$router.push(link);
      }
    },
    msgInfo() {
      this.$toast.default("준비중 입니다.");
      return false;
    },
    day(str) {
      this.$moment().locale();
      const today = this.$moment().format("YYYY-MM-DD");
      const targetDay = this.$moment(str).format("YYYY-MM-DD");
      const diifDay = this.$moment(today).diff(this.$moment(targetDay), "days");
      if (diifDay > 0) {
        return this.$moment(str).format("YY-MM-DD");
      } else {
        return this.$moment(str).format("LT");
      }
    },
    async getAlarmData(reset = false, search = false) {
      if (reset) {
        this.page = 0;
      }
      if (search) {
        this.keyword = "";
      }
      await this.$store.dispatch("mypage/getAlarmData", {
        reset,
        page: this.page,
        keyword: this.keyword,
      });
      this.page++;
    },
  },
};
</script>

<style lang="scss">
.new_alarm {
  position: absolute;
  left: 15px;
  top: 0;
  font-size: 8px;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
  color: white;
  margin-left: 8px;
  margin-top: 3px;
  line-height: 15px;
  text-align: center;
}
</style>
